/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Images
import SmallLogo from 'img/small_logo.inline.svg'
import { log } from 'console'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  color: ${(props) => props.theme.color.text.secondary};

  h2 {
    color: ${(props) => props.theme.color.text.contrast};
    font-size: ${(props) => props.theme.font.size[16]};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  & a {
    color: ${(props) => props.theme.color.text.light};

    &:hover {
      color: ${(props) => props.theme.color.text.light};
      text-decoration: underline;
    }
  }

  ul {
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      min-height: 175px;
      height: 100%;
      width: 1px;
      top: 0;
      left: 3px;
      background-color: ${(props) => props.theme.color.face.contrast};
    }

    li {
      list-style: none;
      line-height: 30px;

      & a {
        color: ${(props) => props.theme.color.text.light};

        &:hover {
          color: ${(props) => props.theme.color.text.light};
          text-decoration: underline;
        }
      }
    }
  }
`

const CertImage = styled(Plaatjie)`
  height: 100%;
  width: auto;

  &:first-of-type {
    /* margin-right: 30px; */
  }

  & img {
    object-fit: contain !important;
  }
`

const LogoContainer = styled.div`
  background-color: ${(props) => props.theme.color.face.contrast};
  padding: 50px 10px 20px 10px;
  position: absolute;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: -3rem;
`

const CertContainer = styled.div`
  height: 150px;
  position: relative;
`

const CertWrapper = styled.div`
  position: absolute;
  top: 40px;
  height: 120px;
  width: 150%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            menu {
              link {
                title
                url
              }
            }
            products {
              link {
                title
                url
              }
            }
            applications {
              link {
                title
                url
              }
            }
            info {
              address
              zipcode
              country
              email {
                url
                title
              }
              phone {
                url
                title
              }
              phoneUs {
                url
                title
              }
            }
            certificatesOne {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400)
                }
              }
            }
            certificatesTwo {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 74)
                }
              }
            }
          }
          social {
            linkedinUrl
            twitterUrl
            youtubeUrl
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <div className="col-6 col-sm pb-sm-0 pb-4">
            <h2>MENU</h2>
            <ul>
              {fields?.footer?.menuFooter?.menu?.map(
                ({ link: { url, title } }: any, index: number) => (
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-6 col-sm pb-sm-0 pb-4">
            <h2>PRODUCTS</h2>
            <ul>
              {fields?.footer?.menuFooter?.products?.map(
                ({ link: { url, title } }: any, index: number) => (
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-6 col-sm pb-sm-0 pb-5">
            <h2>APPLICATIONS</h2>
            <ul>
              {fields?.footer?.menuFooter?.applications?.map(
                ({ link: { url, title } }: any, index: number) => (
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-md-4">
            <p className="mb-0">
              <b>SenseAnywhere</b>
            </p>
            <p className="mb-0">{fields?.footer?.menuFooter?.info?.address}</p>
            <p className="mb-0">{fields?.footer?.menuFooter?.info?.zipcode}</p>
            <p>{fields?.footer?.menuFooter?.info?.country}</p>
            <div>
              <a href={fields?.footer?.menuFooter?.info?.email?.url}>
                {fields?.footer?.menuFooter?.info?.email?.title}
              </a>
            </div>
            <div>
              <a href={fields?.footer?.menuFooter?.info?.phone?.url}>
                EUR: {fields?.footer?.menuFooter?.info?.phone?.title}
              </a>
            </div>
            <div>
              <a href={fields?.footer?.menuFooter?.info?.phoneUs?.url}>
                USA: {fields?.footer?.menuFooter?.info?.phoneUs?.title}
              </a>
            </div>
            <div className="mt-4 d-flex flex-col">
              <a
                href={fields?.footer?.social?.linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                  />
                </svg>
              </a>
              <a
                href={fields?.footer?.social?.twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <svg
                  width="25"
                  height="25"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932zM17.61 20.644h2.039L6.486 3.24H4.298z"
                  />
                </svg>
              </a>
              <a
                href={fields?.footer?.social?.youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="currentColor"
                    d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104l.022.26l.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105l-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006l-.087-.004l-.171-.007l-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103l.003-.052l.008-.104l.022-.26l.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007l.172-.006l.086-.003l.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"
                  />
                </svg>
              </a>
            </div>
            <CertContainer className="d-flex justify-content-sm-start justify-content-center pt-5">
              <CertWrapper className="d-flex">
                {' '}
                <CertImage
                  image={fields?.footer?.menuFooter?.certificatesOne}
                  alt=""
                />
                <CertImage
                  image={fields?.footer?.menuFooter?.certificatesTwo}
                  alt=""
                />
              </CertWrapper>
            </CertContainer>
          </div>
          <div className="col-sm-1 d-lg-block d-none">
            <LogoContainer>
              <SmallLogo />
            </LogoContainer>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
