/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'
import { motion } from 'framer-motion'
import { isBrowser } from 'services/language'

// Images
import SmallLogo from 'img/small_logo.inline.svg'
import Logo from 'img/logo-trademark.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

const StyledHeader = styled.nav`
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 80px;
  z-index: 15;
`

const LogoContainer = styled.div`
  /* background-color: ${(props) => props.theme.color.face.contrast};
  padding: 55px 10px 20px 10px;
  border-radius: 10px; */
`

// @ts-ignore
const StyledSmallLogo = styled(SmallLogo)`
  height: 69px;
  width: 61px;
`

const HeaderTitle = styled(CustomLink)`
  color: ${(props) => props.theme.color.text.light} !important;
  font-family: ${(props) => props.theme.font.family.secondary};
  font-weight: ${(props) => props.theme.font.weight.s};
  font-size: ${(props) => props.theme.font.size[32]};
  line-height: 32px;
  padding: 50px 10px 20px 25px;
  text-decoration: none !important;

  b {
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const StyledLogo = styled(Logo)`
  width: 300px;
  height: auto;
  position: relative;
  top: 13px;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 30px;
    width: 30px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.text.secondary};
  height: 2px;
  width: 50%;
  margin: 5px auto;
  border-radius: 5px;
`

const Submenu = styled(motion.div)`
  width: 250px;
  position: absolute;
  left: -30px;
  display: none;
  top: 20px;
  z-index: 2;
  padding-top: 18px;
  justify-content: center;

  @media screen and (max-width: 991px) {
    padding-top: 0;
    top: 0;
    left: 0;
    justify-content: flex-start;
  }

  .wrap {
    background: #fff;
    border-radius: 8px;

    a[aria-current] {
      color: ${(props) => props.theme.color.main};
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &:hover {
    display: block;
  }

  a {
    text-transform: unset;
    display: block;
    color: ${(props) => props.theme.color.text.main};
  }

  @media (max-width: 991.98px) {
    position: relative;
    width: 100%;

    a:first-child {
      margin-top: 0 !important;
    }

    a {
      font-size: 16px !important;
      padding-left: 20px;
    }
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FunctionComponent<HeaderProps> = ({ setIsScrollable }) => {
  const {
    fields,
    backgroundImage,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpMenu(databaseId: { eq: 2 }) {
        menuItems {
          nodes {
            parentDatabaseId
            label
            url
            target
            childItems {
              nodes {
                parentDatabaseId
                label
                url
              }
            }
          }
        }
      }

      backgroundImage: file(relativePath: { eq: "menu_background.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 2000)
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container className="container">
        <div className="d-flex">
          <Brand to="/">
            <LogoContainer className="d-flex align-items-center">
              <StyledLogo />
            </LogoContainer>
          </Brand>
        </div>
        {/* <HeaderTitle to="/">
          Sense
          <br />
          <b>Anywhere</b>
        </HeaderTitle> */}

        <MenuMobile
          backgroundImage={backgroundImage}
          fields={fields}
          setIsScrollable={setIsScrollable}
        />
        <MenuDesktop fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 100px 0 0;
    list-style-type: none;
    text-align: left;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: ${(props) => props.theme.font.size[24]};
        display: block;
        padding: 10px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 40px;
  top: 30px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.text.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const BackgroundImage = styled(Plaatjie)`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.28;
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
  backgroundImage: any
}

const MenuMobile: React.FunctionComponent<MenuMobileProps> = ({
  fields,
  backgroundImage,
  setIsScrollable,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="d-block position-relative d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <>
          <BackgroundImage alt="" image={{ localFile: backgroundImage }} />
          <MenuWrapper>
            <NavButtonClose
              role="button"
              onClick={() => {
                setIsOpen(!isOpen)
                setIsScrollable(true)
              }}
            >
              <NavButtonCloseCross />
            </NavButtonClose>
            <ul>
              <Menu fields={fields} />
            </ul>
          </MenuWrapper>
        </>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

interface MenuDesktopProps {
  fields: any
}

const MenuDesktop: React.FunctionComponent<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  & > a {
    padding-left: 40px;
    text-transform: uppercase;
    color: ${(props) => props.theme.color.text.light};
    font-family: ${(props) => props.theme.font.family.secondary};
    font-size: ${(props) => props.theme.font.size[12]};
    transition: color 0.3s ease;

    @media screen and (max-width: 1200px) {
      padding-left: 10px;
    }

    &[aria-current] {
      color: ${(props) => props.theme.color.face.contrast};
    }

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.color.text.contrast};
    }
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FunctionComponent<MenuProps> = ({ fields }) => {
  const [isHovered, setHovered] = useState(false)
  const isMobile = isBrowser() && window.innerWidth < 992

  return isMobile
    ? fields.menuItems.nodes.map(({ label, url }: any, index: number) => (
      <Item key={index} className="position-relative">
        <CustomLink to={url}>{label}</CustomLink>
      </Item>
    ))
    : fields.menuItems.nodes.map(
      ({ parentDatabaseId, label, url, target, childItems }: any, index: number) =>
        parentDatabaseId === 0 &&
        (childItems.nodes.length === 0 ? (
          <Item key={index} className="position-relative">
            {target === '_blank' ? (
              <a href={url} target={target}>{label}</a>
            ) : (
              <CustomLink to={url}>{label}</CustomLink>
            )}
          </Item>
        ) : (
          <Item
            onMouseEnter={() => !isMobile && setHovered(true)}
            onMouseLeave={() => !isMobile && setHovered(false)}
            key={index}
            className="position-relative"
          >
            <CustomLink to={url}>{label}</CustomLink>
            {childItems.nodes.length !== 0 && (
              <Submenu
                animate={isHovered ? 'open' : 'collapsed'}
                exit="collapsed"
                variants={{
                  open: { display: 'flex' },
                  collapsed: { display: 'none' },
                }}
                initial={false}
              >
                <div className="wrap pb-1 pb-lg-2 py-1">
                  {childItems.nodes.map((item) => (
                    <CustomLink
                      className="my-lg-3 px-lg-3 text-shadow text-uppercase"
                      to={item.url}
                      key={item.wordpress_id}
                    >
                      {item.label}
                    </CustomLink>
                  ))}
                </div>
              </Submenu>
            )}
          </Item>
        ))
    )
}

export default Header
