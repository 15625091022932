import React, { DOMElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { renderToString } from 'react-dom/server'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Buttons
import ButtonDefault from 'components/elements/Button/ButtonDefault'
import ButtonAlt from 'components/elements/Button/ButtonAlt'
import ButtonOutline from 'components/elements/Button/ButtonOutline'

// Components
import CustomLink from 'components/shared/CustomLink'
import Image from './Image'

const Content = styled.div<{ isLightHs: boolean }>`
  font-weight: ${(props) => props.theme.font.weight.s};
  font-size: ${(props) => props.theme.font.size[16]};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.l};
  }

  & h4 {
    font-family: ${(props) => props.theme.font.family.secondary};
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.s};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.l};
    }

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size[20]};
      line-height: ${(props) => props.theme.font.size[20]};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size[16]};
      line-height: ${(props) => props.theme.font.size[16]};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h3 {
    font-family: ${(props) => props.theme.font.family.secondary};
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.s};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.l};
    }

    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 30px;
    }

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1,
  & h2,
  & h5 {
    font-family: ${(props) => props.theme.font.family.secondary};
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.s};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.l};
    }

    @media (min-width: 992px) {
      font-size: ${(props) => props.theme.font.size[50]};
      line-height: ${(props) => props.theme.font.size[50]};
    }

    @media (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size[32]};
      line-height: ${(props) => props.theme.font.size[32]};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & p {
    line-height: 30px;
    font-size: ${(props) => props.theme.font.size[16]};
  }

  & ul,
  ol {
    & li {
      list-style: none;
      position: relative;
      line-height: 30px;

      &::before {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        left: -35px;
        top: 4px;
        border-radius: 10px;
        border: 3px solid ${(props) => props.theme.color.face.contrast};
      }
    }
  }

  & ol {
    padding-top: 30px;

    & li {
      font-size: 24px;
      line-height: 40px;

      &::before {
        top: 10px;
      }
    }
  }

  & p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
  }

  & table {
    & img {
      object-fit: contain !important;
      height: initial !important;
      width: initial !important;
      top: 10px !important;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
  }

  .inline-gatsby-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = (key: string) => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = (style: any) => {
  const result: any = []

  if (!style) {
    return result
  }

  const attributes = style.split(';')

  attributes.forEach((attribute: any) => {
    const { 0: key, 1: value } = attribute.split(':')

    result[convertCSSKey(key)] = value
  })

  return result
}

const findAndReplace = (content: any, settings: any) =>
  parse(content, {
    replace: (domNode: any) => {
      if (domNode.name === 'a' && domNode.attribs.class === 'custom-button') {
        return (
          <ButtonDefault to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonDefault>
        )
      }

      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'custom-button-alt'
      ) {
        return (
          <ButtonAlt to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonAlt>
        )
      }

      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'custom-button-outline'
      ) {
        return (
          <ButtonOutline to={domNode.attribs.href}>
            {domToReact(domNode.children, {})}
          </ButtonOutline>
        )
      }

     if (domNode.name === 'img') {
        return (
          <img style={{ position: 'relative' }} src={domNode.attribs['data-src']} alt="" />
        )
      }


      if (domNode.children && domNode.children.length > 0) {
        const html = renderToString(
          domToReact(domNode.children, {}) as DOMElement<any, any>
        )
        const htmlFindAndReplace = findAndReplace(html, settings)

        const attributes = domNode.attribs

        if (
          attributes.src &&
          attributes.src.indexOf(settings.sourceUrl) === -1
        ) {
          attributes.src = `${settings.sourceUrl}${attributes.src}`
        }

        if (attributes.href) {
          if (
            attributes.href.indexOf('www.') !== -1 ||
            attributes.href.indexOf('http:') !== -1 ||
            attributes.href.indexOf('https:') !== -1
          ) {
            attributes.target = '_blank'
          } else if (
            attributes.href.indexOf('/wp-content/') !== -1 &&
            attributes.href.indexOf(settings.sourceUrl) === -1
          ) {
            attributes.href = `${settings.sourceUrl}${attributes.href}`
          }
        }

        attributes.className = attributes.class

        delete attributes.class

        if (attributes.style) {
          attributes.style = convertCSS(attributes.style)
        }

        const Comp = domNode.name

        return (
          <Comp
            src={attributes.src}
            className={attributes.className}
            style={attributes.style}
            id={attributes.id}
            href={attributes.href}
            target={attributes.target}
          >
            {htmlFindAndReplace}
          </Comp>
        )
      }

      if (domNode.name === 'a') {
        const attributes = domNode.attribs

        if (attributes.style) {
          delete attributes.style
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1
        ) {
          return (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <a {...attributes} target="_blank">
              {domToReact(domNode.children, {})}
            </a>
          )
        }

        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <CustomLink to={attributes.href} {...attributes}>
            {domToReact(domNode.children, {})}
          </CustomLink>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: any
  className?: string
  isLightHs?: boolean
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
  isLightHs = false,
}) => {
  // eslint-disable-next-line
  const {
    site,
  } = useStaticQuery<GatsbyTypes.parseContentSiteMetaQueryQuery>(graphql`
    query parseContentSiteMetaQuery {
      site {
        siteMetadata {
          siteWpUrl
        }
      }
    }
  `)

  let sourceUrl = ''

  if (site && site.siteMetadata && site.siteMetadata.siteWpUrl) {
    sourceUrl = site.siteMetadata.siteWpUrl
  }

  if (!content) {
    return null
  }

  return (
    <Content className={className} isLightHs={isLightHs}>
      {findAndReplace(content, { sourceUrl })}
    </Content>
  )
}

export default ParseContent
