import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Button/ButtonShell'

const StyledButton = styled.span<{ disabled: boolean }>`
  display: inline-block;

  & > a,
  & > button {
    color: ${(props) => props.theme.color.text.contrast};
    padding: 10px 25px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    font-size: ${(props) => props.theme.font.size[16]};
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.color.face.contrast};
    transition: all 0.3s ease;

    ${(props) =>
      !props.disabled &&
      css`
        &:hover {
          color: ${props.theme.color.text.light};
          background-color: ${props.theme.color.face.contrast};
        }
      `}
  }
`

interface ButtonOutlineProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  children: any
  className?: string
  disabled?: boolean
}

const ButtonOutline: React.FunctionComponent<ButtonOutlineProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  disabled = false,
}) => (
  <StyledButton className={className} disabled={disabled}>
    {isCustom ? (
      children
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonOutline
